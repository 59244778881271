<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="750"
  >
    <v-container>
      <v-card>
        <v-row>
          <v-col
            cols="6"
          >
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
          </v-col>

          </v-col>
          <v-col
            cols="6"
          >
            <v-card-title>
              <span class="text-h5">Total a pagar $ {{ totalpayment }}</span>
            </v-card-title>
          </v-col>
        </v-row>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
            >
              <!-- <v-row v-if="this.$store.getters.usuario.user_rol === 'Encargado'">
                <v-col cols="12">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="datepayment"
                        label="Fecha"
                        persistent-hint
                        :append-icon="icons.mdiCalendar "
                        v-bind="attrs"
                        outlined
                        dense
                        hide-details=""
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datepayment"
                      locale="es"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="total"
                    :append-icon="icons.mdiCashMultiple "
                    :rules="[Validaciones.requeridoOpcional]"
                    label="Total $"
                    type="number"
                    dense
                    hide-details="true"
                    :readonly="onlyReadTotal"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-autocomplete
                    v-model="totalpay"
                    :disabled="loading"
                    :items="creditCollections"
                    :rules="[Validaciones.requeridoOpcional]"
                    filled
                    hide-details="true"
                    dense
                    chips
                    label="Seleccionar Cuota"
                    item-text="description"
                    item-value="id"
                    :loading="loading"
                    :readonly="totalpayReady"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-checkbox
                    v-model="recargoDia"
                    label="Recargo por Visita"
                    color="danger"
                    :value="9"
                    hide-details
                    :readonly="recargoDiaReady"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-checkbox
                    v-model="interesVencimiento"
                    label="interés por Vencimiento"
                    color="warning"
                    :readonly="interesVencimientoReady"
                    :value="8"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-text-field
                    v-if="recargoDia === 9"
                    v-model="amountVisitInterest"
                    :append-icon="icons.mdiCashMultiple "
                    :rules="[Validaciones.requerido]"
                    label="Recargo por Visita $"
                    type="number"
                    dense
                    hide-details="true"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-text-field
                    v-if="interesVencimiento === 8"
                    v-model="percentExpiringInterest"
                    :append-icon="icons.mdiPercentOutline"
                    :rules="[Validaciones.requerido]"
                    label="Interes por vencimiento %"
                    type="number"
                    dense
                    hide-details="true"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="rolUserLogged == 'Administrador' || rolUserLogged == 'Gerente' || rolUserLogged == 'Encargado'">
                <v-col
                  cols="6"
                >
                  <v-autocomplete
                    v-model="paymentTypeSelect"
                    :items="paymentType"
                    :rules="[Validaciones.requerido]"
                    hide-details="true"
                    dense
                    outlined
                    label="Tipo de Pago"
                    item-text="description"
                    item-value="id"
                    :loading="loading"
                    :readonly="totalpayReady"
                    :append-icon="icons.mdiCashRefund"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-text-field
                    v-if="paymentTypeSelect === 10 || paymentTypeSelect === 11"
                    v-model="DescripcionTipoPago"
                    :append-icon="icons.mdiAccountCreditCard"
                    :rules="[Validaciones.requerido]"
                    :label="labelTipoPago"
                    type="text"
                    dense
                    hide-details="true"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="rolUserLogged == 'Cobrador'">
                <v-col
                  cols="6"
                >
                  <v-checkbox
                    v-model="pagoPostergado"
                    label="Pago postergado"
                    color="warning"
                    hide-details="true"
                    :value="1"
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-text-field
                    v-if="pagoPostergado === 1"
                    v-model="motivoFaltadePago"
                    :append-icon="icons.mdiNoteTextOutline"
                    :rules="[Validaciones.requerido]"
                    label="Motivo por falta del pago"
                    type="text"
                    dense
                    hide-details="true"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
              </v-card-actions>
              <br></br>
              <v-row>
                <v-btn
                  color="blue darken-1"
                  text
                  depressed
                  @click="closeDialog()"
                >
                  Cancelar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  :loading="isSaving"
                  depressed
                  @click="ConditionVisit()"
                >
                  Confirmar Pago
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import {
  mdiCashMultiple, mdiCalendar, mdiPercentOutline, mdiNoteTextOutline, mdiAccountCreditCard, mdiCashRefund,
} from '@mdi/js'

export default {
  props: {
    // clientID: {
    //   type: Number,
    //   required: true,

    // },
  },
  data() {
    return {
      datepayment: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1),
      dialog: false,
      collectionId: null,
      motivoFaltadePago: '',
      DescripcionTipoPago: '',
      paymentType: [
        {
          id: 1,
          description: 'Efectivo',
        },
        {
          id: 10,
          description: 'Transferencia',
        },
        {
          id: 11,
          description: 'Bienes',
        },
      ],
      paymentTypeSelect: 1,
      rolUserLogged: null,
      labelTipoPago: '',
      tipoPago: null,
      onlyReadTotal: false,
      totalpayReady: false,
      interesVencimientoReady: false,
      recargoDiaReady: false,
      totalpayment: 0,
      recargoDia: false,
      amountVisitInterest: 0,
      percentExpiringInterest: 0,
      amountExpiringInterest: 0,
      interesVencimiento: false,
      pagoPostergado: false,
      creditCollections: [],
      autoUpdate: true,
      personIDLogged: null,
      creditHistoryId: null,
      amount: 0,
      deudaseleccionada: 0,
      total: 0,
      totalpay: 0,
      lat: '0',
      lon: '0',
      menu: false,
      icons: {
        mdiCalendar,
        mdiCashMultiple,
        mdiPercentOutline,
        mdiNoteTextOutline,
        mdiAccountCreditCard,
        mdiCashRefund,

      },
      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',
        requeridoOpcional: x => {
          if (this.pagoPostergado == true) return true
          if (this.total == 0 || this.total == null) return false

          return true
        },
      },
      loadingButton: false,
      mensajeSnack: '',
      loading: false,
      isSaving: false,
      employeePersonID: null,
    }
  },
  computed: {
    formTitle() {
      return 'Pago de Cuotas'
    },

  },
  watch: {
    paymentTypeSelect() {
      if (this.paymentTypeSelect === 10) {
        this.labelTipoPago = 'Nro. de Transferencia o Descripción'
      }
      if (this.paymentTypeSelect === 11) {
        this.labelTipoPago = 'Descripción para bienes'
      }
    },
    interesVencimiento() {
      if (!this.interesVencimiento) {
        this.percentExpiringInterest = 0
        this.calcularTotalconInteres()
      }
    },
    pagoPostergado() {
      if (this.pagoPostergado == true) {
        this.onlyReadTotal = true
        this.total = 0
        this.totalpay = []
        this.amountVisitInterest = 0
        this.amountExpiringInterest = 0
        this.totalpayReady = true
        this.interesVencimiento = false
        this.recargoDia = false
        this.interesVencimientoReady = true
        this.recargoDiaReady = true
      } else {
        this.onlyReadTotal = false
        this.totalpayReady = false
        this.interesVencimientoReady = false
        this.recargoDiaReady = false
      }
    },

    recargoDia() {
      if (!this.recargoDia) {
        this.amountVisitInterest = 0
        this.calcularTotalconInteres()
      }
    },
    percentExpiringInterest() {
      this.conditionInterest()
      this.calcularTotalconInteres()

      // this.CalcularPagoCuotas()
    },

    amountVisitInterest() {
      this.calcularTotalconInteres()
    },
    total() {
      this.calcularTotalconInteres()
    },
    totalpay() {
      this.CalcularPagoCuotas()
      this.calcularTotalconInteres()
    },
  },
  mounted() {
    this.getLocationData()

    this.rolUserLogged = this.$store.getters.usuario.user_rol
  },
  methods: {
    calcularTotalconInteres() {
      this.amountExpiringInterest = (parseFloat(this.total * parseFloat(this.percentExpiringInterest) / 100))
      this.totalpayment = (parseFloat(this.total) + parseFloat(this.amountVisitInterest) + parseFloat(this.amountExpiringInterest))
    },
    CalcularPagoCuotas() {
      this.totalpayment = 0

      // if (amountvisit == null) { amountvisit = 0 }
      // if (interest == null) { interest = 0 }

      let sumatotal = 0
      this.deudaseleccionada = 0

      this.creditCollections.forEach(element => {
        if (this.totalpay.includes(element.id)) {
          sumatotal += parseFloat(element.amountToPay)
          this.deudaseleccionada = sumatotal
        }
      })
      this.total = parseFloat(sumatotal)
    },
    clearFields() {
      this.total = 0
      this.totalpay = 0
      this.totalpayment = 0
      this.amountExpiringInterest = 0
      this.percentExpiringInterest = 0
      this.amountVisitInterest = 0
      this.collectionId = null
      this.recargoDia = false
      this.interesVencimiento = false
      this.creditCollections = []
      this.creditHistoryId = null
      this.amount = 0
      this.deudaseleccionada = 0
      this.lat = '0'
      this.lon = '0'
      this.motivoFaltadePago = ''
      this.pagoPostergado = false
      this.DescripcionTipoPago = ''
      this.paymentTypeSelect = 1
    },
    conditionInterest() {
      if (this.percentExpiringInterest > 100) {
        this.$store.dispatch('showSnack', {
          text: 'El porcentaje del interés no puede superar el 100%',
          color: 'error',
          timeout: 2500,
        })

        this.percentExpiringInterest = 0
      }
    },

    openDialog(idCreditHistorial) {
      this.creditHistoryId = idCreditHistorial
      this.getCreditCollections()
      this.dialog = true
    },

    closeDialog() {
      this.totalpay = []
      this.dialog = false
    },
    remove() {
      const index = this.collectionId.indexOf(item.date)
      if (index >= 0) this.collectionId.splice(index, 1)

      // this.collectionId = null
      // this.data = null
    },
    close() {
      this.$router.push({ name: '/' })
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },

    async getCreditCollections() {
      this.url = `${this.$store.getters.urlBase}/api/Credit/quotasLeftToPay`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { creditHistoryId: parseFloat(this.creditHistoryId) },
        url: this.url,
      }

      this.loading = true
      await axios(this.url, options)
        .then(res => {
          this.creditCollections = res.data.quotasToPay
          this.loading = false
        })
        .catch(error => {
          if (error.data) {
            this.mensajeSnack = error.data
          } else {
            this.mensajeSnack = 'Error de conexion'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,
          })
          this.loading = false
        })
    },
    async getLocationData() {
      if (!('geolocation' in navigator)) {
        this.lat = '0'
        this.lon = '0'

        return alert(
          'Tu navegador no soporta el acceso a la ubicación. Intenta con otro',
        )
      }

      const onUbicacionConcedida = loc => {
        const location = loc.coords

        this.lat = `${location.latitude}`
        this.lon = `${location.longitude}`
      }
      const onErrorDeUbicacion = err => {
        console.log(`Error obteniendo ubicación: ${err.message}`)
        console.log(`Error obteniendo ubicación: ${err.message}`)
        console.log('Error obteniendo ubicación: ', err)
        this.lat = '0'
        this.lon = '0'
      }

      const optionRequest = {
        enableHighAccuracy: true, // Alta precisión
        maximumAge: 0, // No queremos caché
        timeout: 5000, // Esperar 5 segundos
      }

      navigator.geolocation.getCurrentPosition(
        onUbicacionConcedida,
        onErrorDeUbicacion,
        optionRequest,
      )
    },

    ConditionVisit() {
      if (this.pagoPostergado == true) {
        this.RecordCollectorsVisit()
      } else {
        this.ConditionRecordCollectors()
      }
    },
    ConditionRecordCollectors() {
      let deudatotal = 0

      this.creditCollections.forEach(element => {
        deudatotal += element.amountToPay
      })

      if (this.total > deudatotal) {
        this.$store.dispatch('showSnack', {
          text: 'El monto a pagar no puede superar el total de la deuda',
          color: 'danger',
          timeout: 2500,
        })
      } else if (this.total > this.deudaseleccionada) {
        this.$store.dispatch('showSnack', {
          text: 'El monto a pagar no puede superar el total de cuotas seleccionadas',
          color: 'danger',
          timeout: 2500,
        })
      } else {
        this.RecordCollectors()
      }
    },
    async RecordCollectors() {
      if (this.$refs.form.validate()) {
        this.isSaving = true

        this.url = `${this.$store.getters.urlBase}/api/Credit/recordCollectorCollection`
        await this.getLocationData()
        let documentnumber = 0
        const params = {
          personIDLogged: parseInt(this.$store.getters.usuario.user_personid),
          creditHistoryId: parseInt(this.creditHistoryId),
          amount: parseFloat(this.total),
          quotasPerCreditId: this.totalpay,
          date: this.datepayment,
          AmountVisitInteres: parseFloat(this.amountVisitInterest),
          AmountExpiringInterest: parseFloat(this.amountExpiringInterest),
          xPosition: this.lat,
          yPosition: this.lon,
          paymentTypeId: this.paymentTypeSelect,
          descriptionPayment: this.DescripcionTipoPago,

        }

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            response => {
              this.respuesta = response
              console.log(this.respuesta.data)
              this.isSaving = false
              this.$store.dispatch('showSnack', {
                text: 'Se registró el pago correctamente',
                color: 'success',
                timeout: 2500,
              })
              documentnumber = this.respuesta.data
              this.clearFields()
              this.$router.push({ name: 'reportpaymentdocument', params: { documentnumber } })
              this.closeDialog()
              this.isSaving = false
            },
          )
          .catch(e => {
            if (e.response) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,

            })
            this.isSaving = false
          })
      }
    },
    async RecordCollectorsVisit() {
      if (this.$refs.form.validate()) {
        this.isSaving = true

        this.url = `${this.$store.getters.urlBase}/api/Credit/recordCollectorCollectionVisit`
        await this.getLocationData()
        const params = {
          personIDLogged: parseInt(this.$store.getters.usuario.user_personid),
          description: this.motivoFaltadePago,
          quotasPerCreditId: this.creditCollections[0].id,
          xPosition: this.lat,
          yPosition: this.lon,

        }

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            response => {
              this.respuesta = response
              console.log(this.respuesta.data)
              this.isSaving = false
              this.$store.dispatch('showSnack', {
                text: 'Se registró la visita correctamente.',
                color: 'success',
                timeout: 2500,
              })
              this.clearFields()
              this.closeDialog()
              this.isSaving = false
            },
          )
          .catch(e => {
            if (e.response) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,

            })
            this.isSaving = false
          })
      }
    },

  },
}
</script>
